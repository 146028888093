<template>
    <div id="casos">        
        <div class="vista">
            <div class="row w-full">
                <!--<div class="vx-col lg:w-1/5 sm:w-full">
                <vs-button color="primary"  icon-pack="feather" icon="icon-zap" to="/nuevoCaso">Nuevo Caso</vs-button>
                <br>
                </div>
                <div class="vx-col lg:w-1/5 sm:w-full">
                <vs-button color="primary"  icon-pack="feather" icon="icon-zap" to="/nuevaCotizacion">Cotización Rápida</vs-button>
                <br>
                </div>
                <div class="vx-col lg:w-1/5 sm:w-full">
                <vs-button @click="activePrompt=true;popupActive=false;" class="export">Exportar a tabla de Excel</vs-button>
                <vs-prompt title="Exportar a Excel" class="export-options" cancel-text="Cancelar" @cancle="clearFields" @accept="exportToExcel" accept-text="Exportar" @close="clearFields" :active.sync="activePrompt">
                    <input v-model="fileName" placeholder="Nombre del archivo" class="w-full" />
                    <v-select v-model="selectedFormat" :options="formats" class="my-4" />
                    <div class="flex">
                        <span class="mr-4">Tamaño de la celda automático:</span>
                        <vs-switch v-model="cellAutoWidth">Tamaño automático</vs-switch>
                    </div>
                </vs-prompt>
                </div>-->
                <!--
                <div class="vx-col lg:w-1/5 sm:w-full">
                <ul class="centerx">
                    <li>
                    <vs-radio v-model="filtroCasos" vs-value="1">Solo mis casos</vs-radio>
                    </li>
                    <li>
                    <vs-radio v-model="filtroCasos" vs-value="2">Los casos de mis supervisados</vs-radio>
                    </li>
                    <li>
                    <vs-radio v-model="filtroCasos" vs-value="3">Digital</vs-radio>
                    </li>
                </ul>
                <br>
                </div>
                -->     
            </div>
        </div>
        <br>

        <vs-table 
            :data="casos" 
            max-items="10"
            pagination
            filter
            noDataText="No hay datos para mostrar"
            class="tablaE"
            search>
            <template slot="header">
                <div class="row" style="margin-bottom:-20px">
                    <div class="col-md-3">
                        <h4 class="lista">Lista de casos</h4>
                    </div>

                    <div class="col-md-3">
                        <div>
                          <span>Precalificación</span>
                        </div>
                        <div class="alinear">
                            <span class="lupa" id="addon-wrapping">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g id="vuesax_twotone_filter-search" data-name="vuesax/twotone/filter-search" transform="translate(-492 -701)">
                                        <g id="filter-search">
                                            <path id="Vector" d="M11.01,17.06a2.246,2.246,0,0,1-.91,1.72l-1.41.91a2.058,2.058,0,0,1-3.13-1.72V12.62a3.834,3.834,0,0,0-.81-2.12L.91,6.46A3.323,3.323,0,0,1,0,4.44V2.12A2.051,2.051,0,0,1,2.02,0H15.36a2.027,2.027,0,0,1,2.02,2.02V4.24a3.773,3.773,0,0,1-1.01,2.32" transform="translate(495.31 703.01)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
                                            <g id="Group">
                                                <path id="Vector-2" data-name="Vector" d="M6.4,3.2A3.2,3.2,0,1,1,3.2,0,3.2,3.2,0,0,1,6.4,3.2Z" transform="translate(504.87 711.12)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                <path id="Vector-3" data-name="Vector" d="M1,1,0,0" transform="translate(510.87 717.12)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                            </g>
                                            <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(516 725) rotate(180)" fill="none" opacity="0"/>
                                        </g>
                                    </g>
                                </svg>
                            </span>

                            <select
                              class="search-input vs-input3" 
                              v-model="precalificacionType" style="boder:none;">
                              <option :value="tipo.Id" v-for="tipo in precalificacionTypeList" :key="tipo.Id">
                                {{ tipo.Name }}
                              </option>
                            </select>

                            <!-- <select name="cars" id="estatus" v-model="estatu" class="search-input vs-input3" placeholder="Filtrar por estatus"  aria-label="Username" aria-describedby="addon-wrapping" style="boder:none;">
                                <option :value='tipo.Id' v-for="tipo in socStatus"  :key="tipo.Id">{{tipo.Nombre}}</option>
                            </select> -->

                            <!--<input type="text" class="search-input" placeholder="Filtrar por estatus" aria-label="Username" aria-describedby="addon-wrapping">-->
                        </div> 
                    </div>

                    <div class="col-md-3">
                        <div>
                          <span>&nbsp;</span>
                        </div>
                        <select  v-model="filtroCasos" class="vs-input2" placeholder="Filtrar casos"  aria-label="Username" aria-describedby="addon-wrapping" style="boder:none;">
                            <!--<option :value="0">Filtrar Casos</option>
                            <option :value="1">Solo mis casos</option>
                            <option :value="2">Los casos de mis supervisados</option>
                            <option :value="3">Digital</option>
                            <option :value="3">---------------</option>-->
                            <option :value="1">Todas las simulaciones</option>
                            <option
                              v-if="precalificacionType == 1" 
                              :value="2">Simulación rápida y completa</option>
                            <option
                              v-if="precalificacionType == 1" 
                              :value="6">Precalificacion online</option>
                            <option :value="4">Simulaciones del equipo</option>
                            <option 
                              v-if="precalificacionType == 1"
                              :value="5">Consulta a buro</option>
                            <option :value="3">Inmobiliarias</option>
                        </select>
                    </div>
                </div> 
                
            </template>

            <template slot="thead">
              <vs-th style="background:#F1F4FF; border:none; padding: 10px 0 10px 0" colspan="16">
                  <!-- visible con NINGUNO -->
                  <CasosCustomCols 
                    v-if="precalificacionType == 1"
                    v-bind:filterCaso="Number(filtroCasos)"
                    v-on:colsSelected="updateTable( $event )"
                    v-on:showCasoPrueba="updateCasoPrueba( $event )" 
            />
            </vs-th>

            </template>

            <template
              slot-scope="{data}" >

              <!-- Header | NINGUNO - Default -->
              <vs-tr
                v-if="precalificacionType == 1" 
                style="background-color:#fff;" class="table-head-title">
                <vs-th v-if="searchColTable('Folio')"><span>Folio</span></vs-th>
                <vs-th v-if="searchColTable('Origen')"><span>Origen</span></vs-th>
                <vs-th v-if="searchColTable('Fecha ingreso')"><span>Fecha ingreso</span></vs-th>
                <vs-th v-if="searchColTable('Ejecutivo')"><span>Ejecutivo</span></vs-th>
                <vs-th v-if="searchColTable('Organización')"><span>Organización</span></vs-th>
                <vs-th v-if="searchColTable('Tipo de cuenta')"><span>Tipo de cuenta</span></vs-th>
                <vs-th v-if="searchColTable('Cliente')"><span>Cliente</span></vs-th>
                <vs-th v-if="searchColTable('Correo')"><span>Correo</span></vs-th>
                <vs-th v-if="searchColTable('Teléfono')"><span>Teléfono</span></vs-th>
                <vs-th v-if="searchColTable('Destino')"><span>Destino</span></vs-th>
                <vs-th v-if="searchColTable('Valor Inmueble')"><span>Valor Inmueble</span></vs-th>
                <vs-th v-if="searchColTable('Crédito')"><span>Crédito</span></vs-th>
                <vs-th v-if="searchColTable('Pedido en V3')"><span>Pedido en V3</span></vs-th>
                <vs-th v-if="searchColTable('Estatus')"><span>Estatus</span></vs-th>
                <vs-th v-if="searchColTable('Consulta CC')"><span>Consulta CC</span></vs-th>
                <vs-th v-if="searchColTable('Precalificación SOC')"><span>Precalificación SOC</span></vs-th>
                <vs-th v-if="searchColTable('Precalificación Scotiabank')"><span>Precalificación Scotiabank</span></vs-th>
              </vs-tr>

              <!-- Header |  HIPOTECARIO - Negocio digital -->
              <vs-tr
                v-if="precalificacionType == 2" 
                style="background-color:#fff;" class="table-head-title">
                <vs-th><span>Folio</span></vs-th>
                <vs-th><span>Fecha ingreso</span></vs-th>
                <vs-th><span>Ejecutivo</span></vs-th>
                <vs-th><span>Cliente</span></vs-th>
                <vs-th><span>Correo</span></vs-th>
                <vs-th><span>Teléfono</span></vs-th>
                <vs-th><span>Destino</span></vs-th>
                <vs-th><span>Valor Inmueble</span></vs-th>
                <vs-th><span>Viabilidad</span></vs-th>
                <vs-th><span>Intención de compra</span></vs-th>
              </vs-tr>

              <!-- Header |  AUTO - Negocio digital -->
              <vs-tr 
                v-if="precalificacionType == 3"
                style="background-color:#fff;" class="table-head-title">
                <vs-th><span>Folio</span></vs-th>
                <vs-th><span>Fecha ingreso</span></vs-th>
                <vs-th><span>Ejecutivo</span></vs-th>
                <vs-th><span>Cliente</span></vs-th>
                <vs-th><span>Código postal</span></vs-th>
                <vs-th><span>Correo</span></vs-th>
                <vs-th><span>Teléfono</span></vs-th>
                <vs-th><span>Costo del auto</span></vs-th>
                <vs-th><span>Crédito</span></vs-th>
                <vs-th><span>Enganche</span></vs-th>
                <vs-th><span>Score</span></vs-th>
                <vs-th><span>Viabilidad</span></vs-th>
              </vs-tr>

              <!-- Header |  PYME - Negocio digital -->
              <vs-tr
                v-if="precalificacionType == 4" 
                style="background-color:#fff;" class="table-head-title">
                <vs-th><span>Folio</span></vs-th>
                <vs-th><span>Fecha ingreso</span></vs-th>
                <vs-th><span>Ejecutivo</span></vs-th>
                <vs-th><span>Cliente</span></vs-th>
                <vs-th><span>Correo</span></vs-th>
                <vs-th><span>Teléfono</span></vs-th>
                <vs-th><span>Destino</span></vs-th>
                <vs-th><span>Crédito</span></vs-th>
                <vs-th><span>Viabilidad</span></vs-th>
              </vs-tr>
              
              <!-- General -->
              <vs-tr 
                v-if="data[indextr].Estatus != 14 || casoPrueba" :data="tr" :key="indextr" v-for="(tr, indextr) in data"  :class="data[indextr].StatusCaso == 'Nuevo' && (filtroCasos== '6' ? 'colorNuevo':'' || filtroCasos== '1' ? 'colorNuevo':'' || filtroCasos== '2' ? 'colorNuevo':'' || filtroCasos== '3' ? 'colorNuevo':'' | filtroCasos== '4' ? 'colorNuevo':'')">
                
                <!-- Filtro: Ninguno -->
                <vs-td v-if="searchColTable('Folio') && precalificacionType == 1" :data="data[indextr].Id" class="td-content-data" >
                  <a @click="handleSelected(data[indextr].Id)"> {{data[indextr].Id}}</a>
                </vs-td>

                <vs-td v-if="searchColTable('Origen') && precalificacionType == 1" :data="data[indextr].VersionOrigen" style="font-weight:bold" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].VersionOrigen}}</a>
                </vs-td>

                <vs-td v-if="searchColTable('Fecha ingreso') && precalificacionType == 1" :data="data[indextr].Fecha" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">   {{data[indextr].Fecha}}</a>
                </vs-td>

                <vs-td v-if="searchColTable('Ejecutivo') && precalificacionType == 1" :data="data[indextr].BrokerNombre" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].BrokerNombre}}</a>
                </vs-td>

                <vs-td v-if="searchColTable('Organización') && precalificacionType == 1" :data="data[indextr].Organizacion" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].Organizacion}}</a>
                </vs-td>

                <vs-td v-if="searchColTable('Tipo de cuenta') && precalificacionType == 1" :data="data[indextr].Organizacion" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].TipoEjecutivoV3}}</a>
                </vs-td>

                <vs-td v-if="searchColTable('Cliente') && precalificacionType == 1" :data="data[indextr].SolicitanteNombre" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].SolicitanteNombre}}</a>
                </vs-td>

                <vs-td v-if="searchColTable('Correo') && precalificacionType == 1" :data="data[indextr].Solicitante" class="celda-enlace">
                  <a :href="`mailto:${data[indextr].Solicitante}`" class="enlace-icon">
                    <img :src="'https://cotizador.socasesores.com/uploads/OIP.jpg'"  @click="EnlaceMail(data[indextr].Solicitante)">
                  </a>
                </vs-td>

                <vs-td v-if="searchColTable('Teléfono') && precalificacionType == 1" :data="data[indextr].SolicitanteTelefonoCelular" class="celda-enlace">
                  <a :href="`https://api.whatsapp.com/send?phone=${data[indextr].SolicitanteTelefonoCelular}`" target="_blank" class="enlace-icon">
                    <img :src="'https://cotizador.socasesores.com/uploads/Whatss.jpg'"  @click="EnlaceChat(data[indextr].SolicitanteTelefonoCelular)">      
                  </a>
                </vs-td>

                <vs-td  v-if="searchColTable('Destino') && precalificacionType == 1" :data="data[indextr].Destino" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].DestinoNombre}}</a>
                </vs-td>

                <vs-td v-if="searchColTable('Valor Inmueble') && precalificacionType == 1" :data="data[indextr].ValorInmueble" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">${{data[indextr].ValorInmueble}}</a>
                </vs-td>

                <vs-td v-if="searchColTable('Crédito') && precalificacionType == 1" :data="data[indextr].MontoSolicitado" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">  ${{data[indextr].MontoSolicitado}}</a>
                </vs-td>

                <vs-td v-if="data[indextr].Nuevo == '0' && searchColTable('Pedido en V3') && precalificacionType == 1" :data="data[indextr].Nuevo" class="celda-enlace enlace-icon">
                  <img :src="'https://cotizador.socasesores.com/uploads/tache.png'">
                </vs-td>

                <vs-td v-if="data[indextr].Nuevo > '0' && searchColTable('Pedido en V3') && precalificacionType == 1" :data="data[indextr].Nuevo" class="celda-enlace enlace-icon" >    
                  <img :src="'https://cotizador.socasesores.com/uploads/paloma.png'" class="enlace-icon-check">       
                </vs-td>

                <vs-td v-if="data[indextr].StatusCaso == 'Nuevo' && searchColTable('Estatus') && precalificacionType == 1" :data="data[indextr].StatusCaso" style="color:#4BA84F; text-align: center;">
                  <a @click="handleSelected(data[indextr].Id)"> {{data[indextr].StatusCaso}}</a>
                </vs-td>

                <vs-td v-if="data[indextr].StatusCaso == 'Abierto' && searchColTable('Estatus') && precalificacionType == 1" :data="data[indextr].StatusCaso" style="color:#4BA84F"></vs-td>
                <vs-td v-if="data[indextr].StatusCaso == null && searchColTable('Estatus') && precalificacionType == 1" :data="data[indextr].StatusCaso" style="color:#4BA84F"></vs-td>

                <vs-td v-if="searchColTable('Consulta CC') && data[indextr].ConsultaBuro.length == 0 && precalificacionType == 1" class="celda-enlace enlace-icon">
                  <img :src="'https://cotizador.socasesores.com/uploads/tache.png'">
                </vs-td>
                <vs-td v-if="searchColTable('Consulta CC') && data[indextr].ConsultaBuro.length > 0 && precalificacionType == 1" class="celda-enlace enlace-icon">
                  <img :src="'https://cotizador.socasesores.com/uploads/paloma.png'" class="enlace-icon-check">
                </vs-td>

                <vs-td v-if="searchColTable('Precalificación SOC') && data[indextr].PrecalificacionSoc != 'PrecalificacionSoc' && precalificacionType == 1" class="celda-enlace enlace-icon">
                  <img :src="'https://cotizador.socasesores.com/uploads/tache.png'">
                </vs-td>
                <vs-td v-if="searchColTable('Precalificación SOC') && data[indextr].PrecalificacionSoc == 'PrecalificacionSoc' && precalificacionType == 1" class="celda-enlace enlace-icon">
                  <img :src="'https://cotizador.socasesores.com/uploads/paloma.png'" class="enlace-icon-check">
                </vs-td>

                <vs-td v-if="searchColTable('Precalificación Scotiabank') && data[indextr].PrecalificacionScotiabank.length == 0 && precalificacionType == 1" class="celda-enlace enlace-icon">
                  <img :src="'https://cotizador.socasesores.com/uploads/tache.png'">
                </vs-td>
                <vs-td v-if="searchColTable('Precalificación Scotiabank') && data[indextr].PrecalificacionScotiabank.length > 0 && precalificacionType == 1" class="celda-enlace enlace-icon">
                  <img :src="'https://cotizador.socasesores.com/uploads/paloma.png'" class="enlace-icon-check">
                </vs-td>
                <!-- Filtro: Ninguno -->

                <!-- Filtro: Hipotecario -->
                <vs-td v-if="precalificacionType == 2" :data="data[indextr].Id" class="td-content-data" >
                  <a @click="handleSelected(data[indextr].Id)"> {{data[indextr].Id}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 2" :data="data[indextr].Fecha" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">   {{data[indextr].Fecha}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 2" :data="data[indextr].BrokerNombre" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].BrokerNombre}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 2" :data="data[indextr].SolicitanteNombre" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].SolicitanteNombre}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 2" :data="data[indextr].Solicitante" class="celda-enlace">
                  <a :href="`mailto:${data[indextr].Solicitante}`" class="enlace-icon">
                    <img :src="'https://cotizador.socasesores.com/uploads/OIP.jpg'"  @click="EnlaceMail(data[indextr].Solicitante)"> 
                  </a>
                </vs-td>

                <vs-td v-if="precalificacionType == 2" :data="data[indextr].SolicitanteTelefonoCelular" class="celda-enlace">
                  <a :href="`https://api.whatsapp.com/send?phone=${data[indextr].SolicitanteTelefonoCelular}`" target="_blank" class="enlace-icon">
                  <img :src="'https://cotizador.socasesores.com/uploads/Whatss.jpg'"  @click="EnlaceChat(data[indextr].SolicitanteTelefonoCelular)">       
                  </a>
                </vs-td>

                <vs-td v-if="precalificacionType == 2" :data="data[indextr].Destino" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].DestinoNombre}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 2" :data="data[indextr].ValorInmueble" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">${{data[indextr].ValorInmueble}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 2" :data="data[indextr].Perfil" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">{{data[indextr].Perfil}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 2" :data="data[indextr].InicioTramiteHipotecario" class="td-content-data">
                  <a @click="handleSelected(data[indextr].Id)">{{data[indextr].InicioTramiteHipotecario}}</a>
                </vs-td>
                <!-- Filtro: Hipotecario -->

                <!-- Filtro: Auto -->
                <vs-td v-if="precalificacionType == 3" :data="data[indextr].Precalificacion" class="td-content-data" >
                  <a> {{data[indextr].Precalificacion}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 3" :data="data[indextr].Fecha" class="td-content-data">
                  <a>   {{data[indextr].Fecha}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 3" :data="data[indextr].BrokerNombre" class="td-content-data">
                  <a>  {{data[indextr].BrokerNombre}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 3" :data="data[indextr].Solicitante.SolicitanteNombre" class="td-content-data">
                  <a>  {{data[indextr].Solicitante.SolicitanteNombre}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 3" :data="data[indextr].Solicitante.CodigoPostal" class="td-content-data">
                  <a>  {{data[indextr].Solicitante.CodigoPostal}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 3" :data="data[indextr].Solicitante.Solicitante" class="celda-enlace">
                  <a :href="`mailto:${data[indextr].Solicitante.Solicitante}`" class="enlace-icon">
                    <img :src="'https://cotizador.socasesores.com/uploads/OIP.jpg'"  @click="EnlaceMail(data[indextr].Solicitante.Solicitante)"> 
                  </a>
                </vs-td>

                <vs-td v-if="precalificacionType == 3" :data="data[indextr].Solicitante.SolicitanteTelefonoCelular" class="celda-enlace">
                  <a :href="`https://api.whatsapp.com/send?phone=${data[indextr].Solicitante.SolicitanteTelefonoCelular}`" target="_blank" class="enlace-icon">
                    <img :src="'https://cotizador.socasesores.com/uploads/Whatss.jpg'"  @click="EnlaceChat(data[indextr].Solicitante.SolicitanteTelefonoCelular)">       
                  </a>
                </vs-td>

                <vs-td v-if="precalificacionType == 3" :data="data[indextr].Costo" class="td-content-data">
                  <a>  ${{data[indextr].Costo}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 3" :data="data[indextr].MontoCredito" class="td-content-data">
                  <a>  ${{data[indextr].MontoCredito}}</a>
                </vs-td>
              
                <vs-td v-if="precalificacionType == 3" :data="data[indextr].Enganche" class="td-content-data">
                  <a>  ${{data[indextr].Enganche}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 3" :data="data[indextr].Score" class="td-content-data">
                  <a>  {{data[indextr].Score}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 3" :data="data[indextr].Viabilidad" class="td-content-data">
                  <a>  {{data[indextr].Viabilidad}}</a>
                </vs-td>
                <!-- Filtro: Auto -->

                <!-- Filtro: Pyme -->
                <vs-td v-if="precalificacionType == 4" :data="data[indextr].Precalificacion" class="td-content-data" >
                  <a> {{data[indextr].Precalificacion}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 4" :data="data[indextr].FechaRegistro" class="td-content-data">
                  <a>   {{data[indextr].FechaRegistro}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 4" :data="data[indextr].Broker" class="td-content-data">
                  <a>  {{data[indextr].Broker}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 4" :data="data[indextr].SolicitanteNombre" class="td-content-data">
                  <a>  {{data[indextr].SolicitanteNombre}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 4" :data="data[indextr].Solicitante" class="celda-enlace">
                  <a :href="`mailto:${data[indextr].Solicitante}`" class="enlace-icon">
                    <img :src="'https://cotizador.socasesores.com/uploads/OIP.jpg'"  @click="EnlaceMail(data[indextr].Solicitante)"> 
                  </a>
                </vs-td>

                <vs-td v-if="precalificacionType == 4" :data="data[indextr].SolicitanteTelefonoCelular" class="celda-enlace">
                  <a :href="`https://api.whatsapp.com/send?phone=${data[indextr].SolicitanteTelefonoCelular}`" target="_blank" class="enlace-icon">
                    <img :src="'https://cotizador.socasesores.com/uploads/Whatss.jpg'"  @click="EnlaceChat(data[indextr].SolicitanteTelefonoCelular)">       
                  </a>
                </vs-td>

                <vs-td v-if="precalificacionType == 4" :data="data[indextr].DestinoCreditoEmpresarial" class="td-content-data">
                  <a>  {{data[indextr].DestinoCreditoEmpresarial}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 4" :data="data[indextr].MontoCreditoNecesitas" class="td-content-data">
                  <a>  ${{data[indextr].MontoCreditoNecesitas}}</a>
                </vs-td>

                <vs-td v-if="precalificacionType == 4" :data="data[indextr].Perfil" class="td-content-data">
                  <a>  {{data[indextr].Perfil}}</a>
                </vs-td>
                <!-- Filtro: Pyme -->

              </vs-tr>

            </template>
        </vs-table>


        <!-- <template slot="thead"> -->
            
            <!--<vs-th sort-key="Folio">-->
            <!-- <vs-th>
            Folio
            </vs-th>
            <vs-th v-if=" filtroCasos!='1'">
            Ejecutivo
            </vs-th>
            <vs-th v-if=" filtroCasos=='3'">

            </vs-th>
            <vs-th v-if=" filtroCasos=='2'">
            Origen
            </vs-th>
            <vs-th>
            Fecha ingreso
            </vs-th> -->
            <!--<vs-th sort-key="Cliente">-->
            <!-- <vs-th>
            Cliente
            </vs-th>
            <vs-th>
            Correo
            </vs-th>
            <vs-th>
            Teléfono
            </vs-th>
                    <vs-th>
            Destino
            </vs-th>
                    <vs-th>
            Crédito
            </vs-th>
                    <vs-th>
            Organización
            </vs-th>
            <vs-th v-if=" filtroCasos!='0'">
            Pedido en V3
            </vs-th>
        </template> -->

        <!-- <template slot-scope="{data}" >
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"  :class="data[indextr].StatusCaso == 'Nuevo' && filtroCasos== '3'?'colorNuevo':''">
            <vs-td :data="data[indextr].Id" >
            <a @click="handleSelected(data[indextr].Id)"> {{data[indextr].Id}}</a>
            </vs-td>

            <vs-td v-if=" filtroCasos!='1'" :data="data[indextr].BrokerNombre">
            <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].BrokerNombre}}</a>
            </vs-td>

            <vs-td v-if="data[indextr].StatusCaso == 'Nuevo' && filtroCasos=='3'" :data="data[indextr].StatusCaso" style="color:#4BA84F">
            <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].StatusCaso}}</a>
            </vs-td>

            <vs-td v-if="data[indextr].StatusCaso == 'Abierto' && filtroCasos=='3'" :data="data[indextr].StatusCaso" style="color:#4BA84F">
                
            </vs-td>

            <vs-td v-if=" filtroCasos=='2'" :data="data[indextr].VersionOrigen" style="font-weight:bold">
            <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].VersionOrigen}}</a>
            </vs-td>

            <vs-td :data="data[indextr].Fecha">
            <a @click="handleSelected(data[indextr].Id)">   {{data[indextr].Fecha}}</a>
            </vs-td>

            <vs-td :data="data[indextr].SolicitanteNombre">
            <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].SolicitanteNombre}}</a>
            </vs-td>
            <vs-td :data="data[indextr].Solicitante" class="celda-enlace"> -->
                <!--{{data[indextr].Solicitante}}-->
                <!-- <a :href="`mailto:${data[indextr].Solicitante}`">
                    <img :src="'https://cotizador.socasesores.com/uploads/OIP.jpg'"  @click="EnlaceMail(data[indextr].Solicitante)"  class="enlace-m"> 
                </a>
            </vs-td>
            <vs-td :data="data[indextr].SolicitanteTelefonoCelular" class="celda-enlace"> -->
            <!-- {{data[indextr].SolicitanteTelefonoCelular}}-->
                <!-- <a :href="`https://api.whatsapp.com/send?phone=${data[indextr].SolicitanteTelefonoCelular}`" target="_blank">
                    <img :src="'https://cotizador.socasesores.com/uploads/Whatss.jpg'"  @click="EnlaceChat(data[indextr].SolicitanteTelefonoCelular)" class="enlace-w">       
                </a>
            </vs-td>
            <vs-td :data="data[indextr].Destino">
            <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].DestinoNombre}}</a>
            </vs-td>
            <vs-td :data="data[indextr].MontoSolicitado">
            <a @click="handleSelected(data[indextr].Id)">  ${{data[indextr].MontoSolicitado}}</a>
            </vs-td>
            <vs-td :data="data[indextr].Organizacion">
            <a @click="handleSelected(data[indextr].Id)">  {{data[indextr].Organizacion}}</a>
            </vs-td> -->
            <!--<vs-td :data="data[indextr].PedidoV3">
            <a >  {{data[indextr].PedidoV3}}</a>
            </vs-td>-->
            <!-- <vs-td v-if="data[indextr].Nuevo == '0'" :data="data[indextr].Nuevo" class="celda-enlace">
                <img :src="'https://cotizador.socasesores.com/uploads/tache.png'"  class="enlace-w">
            </vs-td>
            <vs-td v-if="data[indextr].Nuevo > '0'" :data="data[indextr].Nuevo" class="celda-enlace" >
                <img :src="'https://cotizador.socasesores.com/uploads/paloma.png'"  class="enlace-m" style="width: 50%" >       
            </vs-td>
            </vs-tr>
        </template>
        </vs-table> -->

        <div class="excel">
            <button @click="activePrompt=true;popupActive=false;"  class="btn btn-exportar mt-4" >
                <svg xmlns="http://www.w3.org/2000/svg" width="29.871" height="29.871" viewBox="0 0 29.871 29.871">
                    <g id="vuesax_twotone_login" data-name="vuesax/twotone/login" transform="translate(-300 -444)">
                        <g id="login" transform="translate(300 444)">
                            <g id="Group_649" data-name="Group 649" transform="translate(4.979 11.824)">
                                <path id="Vector" d="M0,6.372,3.186,3.186,0,0" transform="translate(9.558)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                <path id="Vector-2" data-name="Vector" d="M0,0H12.658" transform="translate(0 3.186)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            </g>
                            <path id="Vector-3" data-name="Vector" d="M0,0C5.5,0,9.957,3.734,9.957,9.957S5.5,19.914,0,19.914" transform="translate(14.936 4.979)" fill="none" stroke="#26558f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" opacity="0.4"/>
                            <path id="Vector-4" data-name="Vector" d="M0,0H29.871V29.871H0Z" transform="translate(29.871 29.871) rotate(180)" fill="none" opacity="0"/>
                        </g>
                    </g>
                </svg> <strong>Exportar a tabla de Excel</strong>
            </button>

            <vs-prompt title="Exportar a Excel" class="export-options" cancel-text="Cancelar" @cancle="clearFields" @accept="exportToExcel" accept-text="Exportar" @close="clearFields" :active.sync="activePrompt">
                <input type="text" v-model="fileName" placeholder="Nombre del archivo" class="vs-input" />
                <v-select v-model="selectedFormat" :options="formats" class="my-4" />
                <div class="flex">
                    <span class="mr-4">Tamaño de la celda automático:</span>
                    <vs-switch v-model="cellAutoWidth">Tamaño automático</vs-switch>
                </div>
            </vs-prompt>
        </div>
    </div>
        
</template>

<script>
import { defineAsyncComponent } from 'vue'
import md5 from 'js-md5';
import vSelect from 'vue-select'
export default {
    props: {
        guid: {
            type: String,
            required: false
        },
    },
    components: {
        'v-select': vSelect,
        CasosCustomCols: defineAsyncComponent(() => import('@/components/customcols/CasosCustomCols.vue'))
    },
    data(){
        return {
            brokerEmail: "",
            precalificacionType: 1,
            precalificacionTypeList: [
              { Id: 1, Name: "NINGUNO" },
              { Id: 2, Name: "HIPOTECARIO" },
              { Id: 3, Name: "AUTO" },
              { Id: 4, Name: "PYME" },
            ],
            token:'',
            columnasSelected: [],
            casoPrueba: false,
            filtroCasos:'1',
            key:'',
                    casos:[],
            allcasos:[],
                    estatus:[],
                    destinos:[],
            selected:[],
            pedidos:[],
            estatu:'',
            socData:null,
            socStatus:[],
            fileName: '',
            formats:['xlsx', 'csv', 'txt'],
            cellAutoWidth: true,
            selectedFormat: 'xlsx',
            headerTitle: ['Id', 'Ejecutivo','Organizacion', 'Tipo de cuenta','Fecha', 'SolicitanteNombre','Correo electrónico','Teléfono', 'Estatus','Destino','MontoSolicitado'],
            headerVal: ['Id', 'BrokerNombre','Organizacion', 'TipoEjecutivoV3', 'Fecha', 'SolicitanteNombre','Solicitante','SolicitanteTelefonoCelular', 'EstatusNombre','DestinoNombre','MontoSolicitado'],
            headerTitleSup: ['Id', 'Ejecutivo','Origen','Organizacion', 'Tipo de cuenta', 'Fecha', 'SolicitanteNombre','Correo electrónico','Teléfono', 'Estatus','Destino','MontoSolicitado'],
            headerValSup: ['Id', 'BrokerNombre','VersionOrigen', 'Organizacion', 'TipoEjecutivoV3', 'Fecha', 'SolicitanteNombre','Solicitante','SolicitanteTelefonoCelular', 'EstatusNombre','DestinoNombre','MontoSolicitado'],
            popupActive: false,
            activePrompt: false,
            prueba: 0           
        }
    },
	mounted(){
        if (this.guid) {
            const word = this.guid;
            if(word.includes("@")== false){
                this.verificaUsuario()
            }else{

                this.ObtenCredenciales()

            }
        } else {
            if(!JSON.parse(localStorage.getItem('userInfo'))){
                this.$router.push('/login').catch(() => {})
            }else {
                this.getApikey()
                this.getDestinos()
                //this.getSolicitudes()
                this.getEstatus()
            }
        }
        if (this.$store.state.AppActiveUser.WidgetColorPrimario != '' && this.$store.state.AppActiveUser.WidgetColorPrimario) {
            this.$vs.theme({
                primary:this.$store.state.AppActiveUser.WidgetColorPrimario
            })
        }
        this.$vs.loading({
            container: '#casos',
            scale: 0.6
        })
	},
    watch: {
        precalificacionType: function() {
            if ([1,2,3,4].includes(this.precalificacionType)) { 
              /**
               * Si es:
               * - Default
               * - Hipotecario 
               * - Auto
               * - Pyme
               * 
               * Cargo por default "Todas las simulaciones" al hacer el cambio
               */
              this.filtroCasos = '0'; // para activar el watch de filtroCasos
              setTimeout(() => {
                this.filtroCasos = '1';
              }, 100);
            }
        },
        filtroCasos: function() {  //req
            if(this.filtroCasos=='0') {
              this.casos = []; // reinicia
            } else if (this.filtroCasos == '1') {
                this.$vs.loading({
                    container: '#casos',
                    scale: 0.6
                });

                if (this.precalificacionType == 1) { // NINGUNA
                  this.getSolicitudes();
                } else if (this.precalificacionType == 2) {
                  this.getCasosHipotecario(); // HIPOTECARIO - Negocio Digital
                } else if (this.precalificacionType == 3) {
                  this.getCasosAuto(); // AUTO - Negocio Digital
                } else if (this.precalificacionType == 4) {
                  this.getCasosPyme(); // PYME - Negocio Digital
                }

            } else if(this.filtroCasos == '2' || this.filtroCasos == '6' || this.filtroCasos == '5'){  // simulacion rapida y completa & venta digital & consultas a buro de credito
                this.$vs.loading({
                    container: '#casos',
                    scale: 0.6
                })
                //this.getCasosSolicitados()
                this.getCasosPorFiltro()
            }else if(this.filtroCasos == '4'){  // Simulaciones de equipos
                this.$vs.loading({
                    container: '#casos',
                    scale: 0.6
                })

                if (this.precalificacionType == 1) { // NINGUNA
                  this.getCasosSolicitados();
                } else if (this.precalificacionType == 2) {
                  // HIPOTECARIO - Negocio Digital
                  this.getCasosHipotecarioEquipo();
                } else if (this.precalificacionType == 3) {
                  // AUTO - Negocio Digital
                  this.getCasosAuto("2"); // parametro para simulacion de equipos
                } else if (this.precalificacionType == 4) {
                  // PYME - Negocio Digital
                  this.getCasosPyme("1"); // parametro para simulacion de equipos
                }
            } else if(this.filtroCasos == '3'){  // inmobiliaria -> Widget
                this.$vs.loading({
                container: '#casos',
                scale: 0.6
                })
                if (this.precalificacionType == 1) { // NINGUNA
                  this.getCasosWidget()
                } else if (this.precalificacionType == 2) {
                  // HIPOTECARIO - Negocio Digital
                  this.casos = []; // sin resultados por que la query usa versionOrigen widget
                  setTimeout( ()=> {
                    this.$vs.loading.close('#casos > .con-vs-loading');
                  }, 500);
                } else if (this.precalificacionType == 3) {
                  // AUTO - Negocio Digital
                  this.casos = []; // sin resultados
                  setTimeout( ()=> {
                    this.$vs.loading.close('#casos > .con-vs-loading');
                  }, 500);
                } else if (this.precalificacionType == 4) {
                  // PYME - Negocio Digital
                  this.casos = []; // sin resultados
                  setTimeout( ()=> {
                    this.$vs.loading.close('#casos > .con-vs-loading');
                  }, 500);
                }
            }
        },
        estatu: function() {
            if (this.estatu == null) {
                this.casos= this.allcasos
            }else {
                this.filter(this.estatu)
            }
        }
    },
	methods: {
    getCasosPyme(filtro = "0") {
      let self = this;
      let objRequestListaPyme = {
        strApiKey: this.key,
        // strApiKey: "64bdbfe6ad69ab8a74d7606f08d6e8f1",
        strMetodo: 'DameCasoVFinal_PYME',
        objCaso: {
          Broker: this.brokerEmail,
          // Broker: "azamudio@socasesores.com",
          Filtro: filtro
        }
      }
      
      this.$axios.post('/', objRequestListaPyme, 
        { headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'} })
        .then( response => {
          if(response.data.intEstatus == 0) {
            this.allcasos = response.data.objContenido;
            for (var i = 0; i < this.allcasos.length; i++) {
              // formatos numericos
							this.allcasos[i].MontoCreditoNecesitas = this.allcasos[i].MontoCreditoNecesitas.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						}
            // console.log(this.allcasos);
            this.casos = this.allcasos;            
            this.$vs.loading.close('#casos > .con-vs-loading');
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error en DameCasoVFinal_PYME',
              text: response.data.strError,
              color: 'danger',
              position: 'top-right'
            });
          }
        }).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema12',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    },
    getCasosAuto(filtro = "") {
      let self = this;
      let objRequestListaAuto = {
        strApiKey: this.key,
        // strApiKey: "c9a1f1000a51cfcd7e1482d32b7bb383",
        strMetodo: 'ListaCasosAutos',
        objParametros: {
          Limite: 200,
          Filtro: filtro  // 2 para simulaciones de equipo, "" para simulaciones solo del broker que hace la petición.
        }
      }
      
      this.$axios.post('/', objRequestListaAuto, 
        { headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'} })
        .then( response => {
          if(response.data.intEstatus == 0) {
            this.allcasos = response.data.objContenido;

            for (var i = 0; i < this.allcasos.length; i++) {
              // formatos numericos
							this.allcasos[i].Costo = this.allcasos[i].Costo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
							this.allcasos[i].Enganche = this.allcasos[i].Enganche.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");  
							this.allcasos[i].MontoCredito = this.allcasos[i].MontoCredito.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
              this.allcasos[i].BrokerNombre = this.allcasos[i].Brokers.Nombre; 
						}
            
            // console.log(this.allcasos);
            this.casos = this.allcasos;            
            this.$vs.loading.close('#casos > .con-vs-loading');
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error en ListaCasosAutos',
              text: response.data.strError,
              color: 'danger',
              position: 'top-right'
            });
          }
        }).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema11',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    },
    getCasosHipotecario() {
      let self = this
			let objRequestListaCasos = {
        strApiKey: this.key,
        strMetodo: 'ListaCasos2',
        objParametros: {
           Limite: 650,  // limite 800 para prod  // pruebas 200
           NegocioDigital: true
       }
			}
      this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.allcasos = response.data.objContenido;
            for (var i = 0; i < this.allcasos.length; i++) {
							this.allcasos[i].MontoSolicitado= this.allcasos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              // formato numerico valorinmueble
							this.allcasos[i].ValorInmueble= this.allcasos[i].ValorInmueble.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
							for (var j = 0; j < this.destinos.length; j++) {
								if (this.destinos[j].Id == this.allcasos[i].Destino) {
										this.allcasos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase();
								}
							}
							for (var k = 0; k < this.estatus.length; k++) {
								if (this.estatus[k].Id == this.allcasos[i].Estatus) {
										this.allcasos[i].EstatusNombre=(this.estatus[k].Nombre).toLowerCase();
								}
							}    
						}
            this.casos = this.allcasos;
            //console.log(this.casos)
            this.$vs.loading.close('#casos > .con-vs-loading');
          } else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos2',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            });
          }
        }).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema9',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    },
    getCasosHipotecarioEquipo() {
      let self = this
			let objRequestListaCasos = {
        strApiKey: this.key,
        strMetodo: 'ListaCasosJerarquia',
        objParametros: {
          Limite: 200,  // limite 800 para prod  // pruebas 200
          NegocioDigital: true
        }
			}
      this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
        response => {
          if(response.data.intEstatus == 0) {
            this.allcasos = response.data.objContenido;

            for (var i = 0; i < this.allcasos.length; i++) {
              this.allcasos[i].MontoSolicitado= this.allcasos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              for (var j = 0; j < this.destinos.length; j++) {
                if (this.destinos[j].Id == this.allcasos[i].Destino) {
                  this.allcasos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase();
								}
							}
              for (var k = 0; k < this.estatus.length; k++) {
                if (this.estatus[k].Id == this.allcasos[i].Estatus) {
                  this.allcasos[i].EstatusNombre=(this.estatus[k].Nombre).toLowerCase();
                }
							}
            }
            this.casos = this.allcasos;
            this.$vs.loading.close('#casos > .con-vs-loading');
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error en ListaCasosJerarquia',
              text: response.data.strError,
              color: 'danger',
              position: 'top-right'
            })
          }
        }
			).catch(function (error) {
        self.$vs.notify({
          title: 'Ocurrio un error de sistema10',
          text: error,
          color: 'danger',
          position: 'top-right'
        })
      })
    },
    async getTokenSisec(apiKey) {
      let self = this;
      let objRequestToken = {
        strApiKey: apiKey,
        strMetodo: 'DameAuthToken',
      }
      await this.$axios.post('/', objRequestToken,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
        .then( async response => {
          if (!response.data.error) {
            this.token = await response.data.access_token;
            // console.log( 'getTokenSisec', this.token );
          } else {
            this.$vs.notify({
              title: 'Ocurrio un error al obtener token',
              text: response.data.error_description,
              color: 'danger',
              position: 'top-right',
            })
          }
        }).catch(function (error) {
          self.$vs.notify({
            title: 'Ocurrio un error de sistema Auth',
            text: error,
            color: 'danger',
            position: 'top-right',
          })
        });
    },
        // columnas personalizadas
        updateTable( cols ) {
            this.columnasSelected = cols;
             //console.log( this.columnasSelected );
        },
        searchColTable( col ) {
            let isEnabled = this.columnasSelected.find(e => e.label == col);
            return isEnabled;
        },
        updateCasoPrueba( value ) {
            this.casoPrueba = value;
        },
    
    handleSelected(tr) {
      this.$router.push('/caso/'+tr).catch(() => {})
    },
    filter(id){
      var newArray = this.allcasos.filter(function (el) {
        return el.Estatus == id;
      });
      this.casos=newArray
    },
    getApikey(){
      this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      this.brokerEmail = JSON.parse(localStorage.getItem('userInfo')).EMail;
    },
    EnlaceChat(numeroSolicitante){
      var WhatsPrint =  window.open('https://api.whatsapp.com/send?phone='+numeroSolicitante);
    },
    EnlaceMail(emailSolicitante){
      window.location.href = "mailto:"+emailSolicitante;
    },
		getDestinos(){
      let self=this
			var objRequestListaDestinos = {
				strApiKey: this.key,
				strMetodo: 'ListaDestinos',
			}
			this.$axios.post('/',objRequestListaDestinos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						this.destinos=response.data.objContenido
            this.getSolicitudes()
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaDestinos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema1',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
		getEstatus(){
      let self=this
			var objRequestListaCasoEstatus = {
				strApiKey: this.key,
				strMetodo: 'ListaCasoEstatus',
			}
			this.$axios.post('/',objRequestListaCasoEstatus,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
						this.estatus=response.data.objContenido
            this.estatus.sort((a,b) => (a.Id > b.Id) ? 1 : ((b.Id > a.Id) ? -1 : 0));
            for (var i = 0; i < this.estatus.length; i++) {
              if (this.estatus[i].Id== 1 || this.estatus[i].Id== 2 || this.estatus[i].Id== 3 || this.estatus[i].Id== 5 || this.estatus[i].Id== 11) {
                this.socStatus.push(this.estatus[i])
                this.socStatus.sort((a,b) => (a.Id > b.Id) ? 1 : ((b.Id > a.Id) ? -1 : 0));
              }
            }
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasoEstatus',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema2',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
    getCasosSolicitados(){
      let self=this
			var objRequestListaCasos = {
				strApiKey: this.key,
				strMetodo: 'ListaCasosJerarquia',
        objParametros: {
           Limite:200  // limite 800 para prod  // pruebas 200
       }
			}
			this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            //this.casos= response.data.objContenido
            this.allcasos=response.data.objContenido
            this.cuentaV3Pedidos()
            
						for (var i = 0; i < this.allcasos.length; i++) {
							this.allcasos[i].MontoSolicitado= this.allcasos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							for (var j = 0; j < this.destinos.length; j++) {
								if (this.destinos[j].Id == this.allcasos[i].Destino) {
										this.allcasos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase()
								}
							}
							for (var k = 0; k < this.estatus.length; k++) {
								if (this.estatus[k].Id == this.allcasos[i].Estatus) {
										this.allcasos[i].EstatusNombre=(this.estatus[k].Nombre).toLowerCase()
								}
							}
						}
            this.casos=this.allcasos
            this.$vs.loading.close('#casos > .con-vs-loading')
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
          
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema3',
          text:error,
          color:'danger',
          position:'top-right'
        })
			}) 
    },
		getSolicitudes(){
      let self=this
			var objRequestListaCasos = {
				strApiKey: this.key,
				strMetodo: 'ListaCasos2',
        objParametros: {
           Limite:650  // limite 800 para prod  // pruebas 200
       }
			}
			this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            this.allcasos=response.data.objContenido
            this.cuentaV3Pedidos()
            for (var i = 0; i < this.allcasos.length; i++) {
							this.allcasos[i].MontoSolicitado= this.allcasos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              // formato numerico valorinmueble
							this.allcasos[i].ValorInmueble= this.allcasos[i].ValorInmueble.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							for (var j = 0; j < this.destinos.length; j++) {
								if (this.destinos[j].Id == this.allcasos[i].Destino) {
										this.allcasos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase()
								}
							}
							for (var k = 0; k < this.estatus.length; k++) {
								if (this.estatus[k].Id == this.allcasos[i].Estatus) {
										this.allcasos[i].EstatusNombre=(this.estatus[k].Nombre).toLowerCase()
								}
							}    
						}
                this.casos = this.allcasos
                //console.log(this.casos)
                this.$vs.loading.close('#casos > .con-vs-loading')
            }else {
              this.$vs.notify({
                title:'Ocurrio un error en ListaCasos',
                text:response.data.strError,
                color:'danger',
                position:'top-right'
              })
            }
          }
        ).catch(function (error) {
          self.$vs.notify({
            title:'Ocurrio un error de sistema4',
            text:error,
            color:'danger',
            position:'top-right'
          })
        })

              
		},
    getCasosPorFiltro(){
      let self=this
			var objRequestListaCasos = {
				strApiKey: this.key,
				strMetodo: 'ListaCasosPorFiltro',
        objFiltro:{
          filtroCasos: this.filtroCasos
        }
			}
			this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            this.allcasos=response.data.objContenido
            this.cuentaV3Pedidos()
            for (var i = 0; i < this.allcasos.length; i++) {
							this.allcasos[i].MontoSolicitado= this.allcasos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              // formato numerico valorinmueble
							this.allcasos[i].ValorInmueble= this.allcasos[i].ValorInmueble.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							for (var j = 0; j < this.destinos.length; j++) {
								if (this.destinos[j].Id == this.allcasos[i].Destino) {
										this.allcasos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase()
								}
							}
							for (var k = 0; k < this.estatus.length; k++) {
								if (this.estatus[k].Id == this.allcasos[i].Estatus) {
										this.allcasos[i].EstatusNombre=(this.estatus[k].Nombre).toLowerCase()
								}
							}    
						}
                this.casos = this.allcasos
                this.$vs.loading.close('#casos > .con-vs-loading')
              }else {
                this.$vs.notify({
                  title:'Ocurrio un error en ListaCasos',
                  text:response.data.strError,
                  color:'danger',
                  position:'top-right'
                })
              }
            }
          ).catch(function (error) {
            self.$vs.notify({
              title:'Ocurrio un error de sistema4',
              text:error,
              color:'danger',
              position:'top-right'
            })
          })
    },
    getCasosWidget(){
      let self=this
			var objRequestListaCasosWidget = {
				strApiKey: this.key,
				strMetodo: 'ListaCasosWidget',
        objParametros: {
           ApiKey:this.key
       }
			}
			this.$axios.post('/',objRequestListaCasosWidget,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            this.allcasos= response.data.objContenido
            //this.allcasos=this.casos
            this.cuentaV3Pedidos()
           
						for (var i = 0; i < this.allcasos.length; i++) {
							this.allcasos[i].MontoSolicitado= this.allcasos[i].MontoSolicitado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							this.allcasos[i].StatusCaso=this.allcasos[i].StatusCaso
              this.allcasos[i].VersionOrigen=this.allcasos[i].VersionOrigen
              
              for (var j = 0; j < this.destinos.length; j++) {
								if (this.destinos[j].Id == this.allcasos[i].Destino) {
										this.allcasos[i].DestinoNombre=(this.destinos[j].Nombre).toLowerCase()
								}
							}
							for (var k = 0; k < this.estatus.length; k++) {
								if (this.estatus[k].Id == this.allcasos[i].Estatus) {
										this.allcasos[i].EstatusNombre=(this.estatus[k].Nombre).toLowerCase()
								}
							}
						}
            this.casos = this.allcasos
            this.$vs.loading.close('#casos > .con-vs-loading')
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema5',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
    async verificaUsuario(){
      let apiKey = await JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      await this.getTokenSisec(apiKey);

      let self=this
      let header = {
        // "authorization": "Bearer 16JXvQ9CD1eEoVESY3-4d-bElrAAgAfcB0UgYwAB-XyuNugkcBvgntr6n6VjEAFWenS9Exi_jlLv_RBfmf004TXytHP64dNEuSyhlXOBDtO56hW07txWd4evNWfukK5CO1ETRIOk8GWyjjS7daQ8eLHUz3XefoKEHKE0yIy76o8rZZYTGskAn1Zo3oKcPFzfs_fmVlO15cD_Kxx27-1qqDBsfAphhY7N599ly5ppo5L4XHaL8JAv6mTxXVYxrTZPQDmnLqQ9Nwre7Bd4URM5rR3rVMrAVkPzJ8ohQ9-pgvigB-Fra_SMi31c5T-QrSTB"
        "authorization": "Bearer " + this.token
      }
      this.$axios.get('https://apivalidacionriesgos.sisec.mx/api/External/GetEmployee?id='+this.guid,{headers : header})
      .then(
        response => {
          this.socData=response.data
          if (this.socData.Status != 'Activo') {
            this.$router.push('/login').catch(() => {})
          }else {
            if (this.socData.OwnerMail === this.socData.Mail) {
              this.autenticaEjecutivoSOC(
                this.socData.Mail,
                this.guid,
                this.socData.EmployeeName,
                this.socData.CommercialReason,
                this.socData.OwnerMail,
                this.socData.OwnerId,
                this.socData.OwnerName,
                this.socData.BrokerId,
                true,
                this.socData.Curp,
                this.socData.Rfc,
                this.socData.CurpCommercial,
                this.socData.RfcCommercia,
                this.socData.EmployeeRol
              )
            }else {
              this.autenticaEjecutivoSOC(
                this.socData.OwnerMail,
                this.socData.OwnerId,
                this.socData.OwnerName,
                this.socData.CommercialReason,
                this.socData.OwnerMail,
                this.socData.OwnerId,
                this.socData.OwnerName,
                this.socData.BrokerId,
                false,
                this.socData.Curp,
                this.socData.Rfc,
                this.socData.CurpCommercial,
                this.socData.RfcCommercia,
                this.socData.EmployeeRol
              )

              //  supervisor
              this.autenticaEjecutivoSOC(
                this.socData.Mail,
                this.guid,
                this.socData.EmployeeName,
                this.socData.CommercialReason,
                this.socData.OwnerMail,
                this.socData.OwnerId,
                this.socData.OwnerName,
                this.socData.BrokerId,
                true,
                this.socData.Curp,
                this.socData.Rfc,
                this.socData.CurpCommercial,
                this.socData.RfcCommercia,
                this.socData.EmployeeRol
              )
            }
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema6',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    autenticaEjecutivoSOC(email,pass,nombre,CommercialReason,supervisor,idSupervisor,nameSupervisor, idBroker, isSupervisor,curp,rfc,curpSupervisor,rfcSupervisor, EmployeeRol ){
       if(EmployeeRol=='Asesor'){
        this.TipoPerfilEjecutivo=5
      }else if(EmployeeRol=='CUENTA MAESTRA' || EmployeeRol=='Cuenta maestra 3.0' || EmployeeRol=='Cuenta Maestra Venta Cruzada' || EmployeeRol=='CuentaMaestraSOC'){
        this.TipoPerfilEjecutivo=1
      }else if(EmployeeRol=='Gerente'){
        this.TipoPerfilEjecutivo=2
      }else if(EmployeeRol=='Operador' || EmployeeRol=='Operador 3.0' || EmployeeRol=='Operador Venta Cruzada' || EmployeeRol=='OperadorSOC' ){
        this.TipoPerfilEjecutivo=3
      }else if(EmployeeRol=='OPERADORADMINISTRADOR'){
        this.TipoPerfilEjecutivo=4
      }
      
      let self=this
      var objRequestAutentica = {
        strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'autenticaEjecutivoSOC',
        objEjecutivo: {
          EMail: email,
          Password: md5(pass),
          Guid: this.guid,
          Nombre: nombre,
          Curp: curp,
          Rfc: rfc, 
          RazonComercial: CommercialReason,
          TipoPerfilEjecutivo: this.TipoPerfilEjecutivo,
          //BrokerId: idBroker
        }
      }
      this.$axios.post('/',objRequestAutentica,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (isSupervisor) {
               this.login(email,pass,nombre,CommercialReason,curp,rfc,EmployeeRol)
            }else {
              return
            }
          }else {
            this.registraEjecutivo(email,pass,nombre,CommercialReason,supervisor,idSupervisor,nameSupervisor,idBroker,isSupervisor,curp,rfc,curpSupervisor,rfcSupervisor,EmployeeRol)
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema7',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    registraEjecutivo(email,pass,nombre,CommercialReason,supervisor,idSupervisor,nameSupervisor,idBroker,isSupervisor,curp,rfc,curpSupervisor,rfcSupervisor,EmployeeRol){
      if(EmployeeRol=='Asesor'){
        this.TipoPerfilEjecutivo=5
      }else if(EmployeeRol=='CUENTA MAESTRA' || EmployeeRol=='Cuenta maestra 3.0' || EmployeeRol=='Cuenta Maestra Venta Cruzada' || EmployeeRol=='CuentaMaestraSOC'){
        this.TipoPerfilEjecutivo=1
      }else if(EmployeeRol=='Gerente'){
        this.TipoPerfilEjecutivo=2
      }else if(EmployeeRol=='Operador' || EmployeeRol=='Operador 3.0' || EmployeeRol=='Operador Venta Cruzada' || EmployeeRol=='OperadorSOC' ){
        this.TipoPerfilEjecutivo=3
      }else if(EmployeeRol=='OPERADORADMINISTRADOR'){
        this.TipoPerfilEjecutivo=4
      }


      let self=this
      var objRequestRegistraEjecutivo={}
      if(!isSupervisor){
        objRequestRegistraEjecutivo = {
          strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
          strMetodo: "RegistraEjecutivo",
          objEjecutivo: {
            EMail: email,
            Nombre: nombre,
            Telefono:'1234567890',
            Password: md5(pass),
            TipoBroker: 1,
            Organizacion: 3,
            Activo: 1,
            BuroCredito: true,
            EMailSupervisor:supervisor,
            SocGuid : idSupervisor,
            OwnerId: idSupervisor,
            OwnerName: nameSupervisor,
            OwnerMail: supervisor,
            BrokerId: idBroker,
            CommercialReason: CommercialReason,
            Curp:curp,
            Rfc:rfc,
           // OwnerCurp: curpSupervisor,
           // OwnerRfc: rfcSupervisor,
            TipoPerfilEjecutivo: this.TipoPerfilEjecutivo
            
          }
        }
      }else {
        objRequestRegistraEjecutivo = {
          strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
          strMetodo: "RegistraEjecutivo",
          objEjecutivo: {
            EMail: email,
            Nombre: nombre,
            Telefono:'1234567890',
            Password: md5(pass),
            TipoBroker: 1,
            Organizacion: 3,
            Activo: 1,
            BuroCredito: true,
            SocGuid : this.guid,
            OwnerId: idSupervisor,
            OwnerName: nameSupervisor,
            OwnerMail: supervisor,
            BrokerId: idBroker,
            CommercialReason: CommercialReason,
            Curp:curp,
            Rfc:rfc,
            CurpCommercial: curpSupervisor,
            RfcCommercia: rfcSupervisor,
            TipoPerfilEjecutivo: this.TipoPerfilEjecutivo

          }
        }
      }

      this.$axios.post('/',objRequestRegistraEjecutivo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            if (supervisor) {
              //this.login(email,pass)
                this.login(email,pass,nombre,CommercialReason,curp,rfc, EmployeeRol)
            }else {
              return
            }

          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraEjecutivo',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema8',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    login(email,pass,nombre,CommercialReason,curp,rfc,EmployeeRol){

      if(EmployeeRol=='Asesor'){
        this.TipoPerfilEjecutivo=5
      }else if(EmployeeRol=='CUENTA MAESTRA' || EmployeeRol=='Cuenta maestra 3.0' || EmployeeRol=='Cuenta Maestra Venta Cruzada' || EmployeeRol=='CuentaMaestraSOC'){
        this.TipoPerfilEjecutivo=1
      }else if(EmployeeRol=='Gerente'){
        this.TipoPerfilEjecutivo=2
      }else if(EmployeeRol=='Operador' || EmployeeRol=='Operador 3.0' || EmployeeRol=='Operador Venta Cruzada' || EmployeeRol=='OperadorSOC' ){
        this.TipoPerfilEjecutivo=3
      }else if(EmployeeRol=='OPERADORADMINISTRADOR'){
        this.TipoPerfilEjecutivo=4
      }

      let self=this
      var objRequestAutentica = {
        strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'autenticaEjecutivoSOC',
        objEjecutivo: {
        EMail: email,
        Password: md5(pass),
        Guid: this.guid,
        Nombre:nombre,
        Curp: curp,
        Rfc:rfc,
        RazonComercial: CommercialReason,
        TipoPerfilEjecutivo: this.TipoPerfilEjecutivo
        }
      }
      this.$axios.post('/',objRequestAutentica,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){

            if(response.data.objContenido.SesionMultilogin==false && response.data.objContenido.TipoEjecutivoV3=='SOC' && (response.data.objContenido.SocGuid!= null || response.data.objContenido.SocGuid!='' )){
                
                this.$cookie.delete('key');
                localStorage.removeItem('userInfo');
                localStorage.removeItem('socData');
                this.$router.push('/login').catch(() => {})
            //   console.log(anali1)
                
            }else{
                // alert(response.data.objContenido.SesionMultilogin+response.data.objContenido.TipoEjecutivoV3+response.data.objContenido.SocGuid)
               this.$store.commit('UPDATE_USER_INFO',response.data.objContenido)
                this.$store.commit('UPDATE_SOC_DATA',this.socData)
                this.getApikey()
                //this.getSolicitudes()
                this.getDestinos()
                this.getEstatus()
            }



          }else {
            this.$vs.notify({
              title:'Ocurrio un error en autenticaEjecutivoSOC',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema Aute',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    ObtenCredenciales(){
      let self=this
      var objRequestAutentica = {
        strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'DameDatosInmobiliario',
        objEjecutivo: {
        email: this.guid
        }
      }
      this.$axios.post('/',objRequestAutentica,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){




                localStorage.setItem('userInfo',JSON.stringify(response.data.objContenido))
                this.$store.commit('UPDATE_USER_INFO',response.data.objContenido)
                this.$router.push('/inmobiliaria').catch(() => {})



          }else {
            this.$vs.notify({
              title:'Ocurrio un error en autenticaEjecutivoSOC',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema Aute',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    exportToExcel () {
      /**
       * 2024-03-04
       * Filtros:
       * 
       * 1 - Todas las simulaciones
       * 2 - Simulacion rapida y completa
       * 3 - Inmobiliarias
       * 4 - Simulaciones del equipo
       * 5 - Consulta a buro
       * 6 - Precalificacion online
       * 
       * Precalificacion:
       * 
       * 1 - Ninguna (default)
       * 2 - Hipotecario / Negocio digital
       * 3 - Auto
       * 4 - Pyme
       */

      if (this.precalificacionType == 1) {
        if(this.filtroCasos == '2') {
          this.headerVal = this.headerValSup
          this.headerTitle = this.headerTitleSup
        } else {
          this.headerVal = this.headerVal
          this.headerTitle = this.headerTitle
        }
      } else if (this.precalificacionType == 2) {
        this.headerTitle = ['Folio', 'Fecha Ingreso', 'Ejecutivo', 'Cliente', 'Correo', 'Teléfono', 'Destino', 'Valor Inmueble', 'Viabilidad', 'Intención de compra'];
        this.headerVal = ['Id', 'Fecha', 'BrokerNombre', 'SolicitanteNombre', 'Solicitante', 'SolicitanteTelefonoCelular', 'DestinoNombre', 'ValorInmueble', 'Perfil', 'InicioTramiteHipotecario'];
      } else if (this.precalificacionType == 3) {
        this.headerTitle = ['Folio', 'Fecha Ingreso', 'Ejecutivo', 'Cliente', 'Código postal', 'Correo', 'Teléfono', 'Costo del auto', 'Crédito', 'Enganche', 'Score', 'Viabilidad'];
        this.headerVal = ['Precalificacion', 'Fecha', 'BrokerNombre', 'Solicitante.SolicitanteNombre', 'Solicitante.CodigoPostal', 'Solicitante.Solicitante', 'Solicitante.SolicitanteTelefonoCelular', 'Costo', 'MontoCredito', 'Enganche', 'Score', 'Viabilidad'];
      } else if (this.precalificacionType == 4) {
        this.headerTitle = ['Folio', 'Fecha Ingreso', 'Ejecutivo', 'Cliente', 'Correo', 'Teléfono', 'Destino', 'Crédito', 'Viabilidad'];
        this.headerVal = ['Precalificacion', 'FechaRegistro', 'Broker', 'SolicitanteNombre', 'Solicitante', 'SolicitanteTelefonoCelular', 'DestinoCreditoEmpresarial', 'MontoCreditoNecesitas', 'Perfil'];
      }
                            
      import('@/vendor/Export2Excel').then(excel => {
        const list = this.allcasos
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
         this.filename = ''
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        
        // para subniveles de objetos
        if (j.includes('.')) {
          let sj = j.split('.');
          return v[sj[0]][sj[1]]; // TODO: Tal vez no sea muy eficiente
        }

        return v[j]
      }))
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    cuentaV3Pedidos(){
      let self=this
   
      for (var o = 0; o < this.allcasos.length; o++) {
              var objRequestCuenta = {
              strApiKey: this.key,
              strMetodo: 'CuentaPedido',
              objParametrosPre: {
                Precalificacion: this.allcasos[o].Id,
                Numeracion: o,
                  }
                }
                this.$axios.post('/',objRequestCuenta,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
                .then(
                  response => {
                    var num = parseInt(response.data.objCuenta)
                    //console.log(num)
                    this.allcasos[num].Nuevo = response.data.objContenido
                    
                     
                  }
                ).catch(function (error) {
                  self.$vs.notify({
                    title:'Ocurrio un error de sistema',
                    text:error,
                    color:'danger',
                    position:'top-right'
                  })
                })  
               
            }
            
       
    },
	}
}
</script>
<style lang="css" scoped>
.vs-table--header {
    display: block !important;
    padding-bottom: 0px !important;
    margin-bottom: -20px !important;
}
.vs-con-tbody{
  background-color: #000 !important;
}
.vs-input {
    padding: 0px;
    border-radius: 0 5px 5px 0 !important;}
.vs-input2 {
    padding: 0px;
    border-radius: 5px !important;
    border: none;
    margin-top: 10px;
    /* max-width: 190px !important; */

    }
.vs-input3 {
    padding: 0px;
    border-radius:0 5px 5px 0 !important;
    border: none;
    margin-top: 10px;
    max-width: 180px !important;
    /* margin-left: -6px; */

    }
    select{padding: 0.55rem !important;}
.alinear{
  margin-bottom: 10px;
}
.lupa {
    margin-top: 10px;
    border: none;
    padding: 6px;
    background: #fff;
    border-radius: 5px 0 0 5px;
        -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 15%);
    /* margin-left: 255px; */
}


/*  
 .vuesax-app-is-ltr .vs-table--search-input{
   padding-left: 1.5em !important;
 } */

select#estatus.search-input{border: none !important;
}


.w-full{padding-top: 0 !important;}
.excel{
  float: right;
    margin-right: 20px;
      position: relative;
      top: -50px;
      font-weight: 600;
  }
.colorNuevo{
  background: #B7DCB8;
}
@media screen and (max-width:1180px){
  h4.lista {
                width: 200px;
            }
 /* .lupa {
    border-radius: 5px 0 0 5px;
    margin-left: 150px;
}
 .vs-input2{
  margin-left: 150px;
} */
}
@media screen and (max-width:1024px) {
   h4.lista {
                width: 180px;
            }
   /* .lupa {
    margin-left: 90px;
}
.vs-input2{
  margin-left: 100px;
}
   */
}
@media screen and (max-width:915px) {
   .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }
  .excel{
    float: none;
    margin-right: auto;
    margin-left: auto;
    top:0;
    max-width: 300px;
  }
  .col-md-1{
    width: 100%;
  }
  
}
@media screen and (max-width:896px) {
   .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }
  .excel{
    float: none;
    margin-right: auto;
    margin-left: auto;
    top:0;
    max-width: 300px;
  }
  .col-md-1{
    width: 100%;
  }
  
}

@media screen and (max-width:768px) {
  .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }
  .excel{
    float: none;
    margin-right: auto;
    margin-left: auto;
    top:0;
    max-width: 300px;
  }}

@media screen and (max-width:667px){
    .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }
  
}
@media screen and (max-width:656px) {
 
  .vs-input2,
  .lupa{
    margin-left: 0px;
    background-color: #fff !important;
  }
  .vs-input2,
  .vs-input3{
     max-width: 100% !important;
     width: 100%;
     background-color: #fff !important;
      }
      .alinear-input{
        margin-right: auto;
        margin-left: auto;
      }
  .excel{
    float: none;
    margin-right: auto;
    margin-left: auto;
    top:0;
    max-width: 300px;
  }
}
</style>
